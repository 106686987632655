import NextImage from 'next/image'
import styled from 'styled-components'

import { Link } from 'components/ui'
import { ButtonLink } from 'components/ui/deprecated/ButtonLink'
import { Text } from 'components/ui/deprecated/Text'
import { useTranslation } from 'lib/i18n'
import { getStoryblokImageAttributes } from 'lib/utils/content'
import { removeLeadingAndTrailingSlash } from 'lib/utils/string'

type Props = {
  name: string
  city: string
  postalCode: string
  image: string
  link: string
  contactLink?: string
  phoneNumber?: string
}

export const ClinicThumb = ({
  name,
  city,
  postalCode,
  image,
  link,
  contactLink,
  phoneNumber,
  ...props
}: Props): JSX.Element => {
  const { i18n } = useTranslation()

  const url = link.startsWith('http')
    ? link
    : `/${removeLeadingAndTrailingSlash(link)}`
  let contactUrl
  if (contactLink) {
    contactUrl = contactLink.startsWith('http')
      ? contactLink
      : `/${removeLeadingAndTrailingSlash(contactLink)}`
  }

  return (
    <Item {...props}>
      <ImageHolder>
        <div style={{ position: 'relative' }}>
          <NextImage
            src={
              getStoryblokImageAttributes(
                { filename: image },
                '400x400/smart/filters:format(webp)'
              ).src
            }
            alt={name}
            height={150}
            width={150}
          />
        </div>
      </ImageHolder>
      <Content>
        <div>
          <Title as="h2" variant="title/small/regular">
            {name}
          </Title>
          <Text as="p" variant="sixteen">
            {postalCode} {city}
          </Text>
          {phoneNumber && (
            <PhoneWrapper as="p" variant="sixteen">
              <Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link>
            </PhoneWrapper>
          )}
        </div>
        {!contactUrl ? (
          <ButtonWrapper>
            <ButtonLink
              href={url}
              variant="ghost"
              size="small"
              leftIcon="external"
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n('visitClinic')}
            </ButtonLink>
          </ButtonWrapper>
        ) : (
          <ButtonWrapper>
            <ButtonLink
              href={contactUrl}
              variant="ghost"
              size="small"
              leftIcon="external"
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n('contactClinic')}
            </ButtonLink>
          </ButtonWrapper>
        )}
      </Content>
    </Item>
  )
}

const Item = styled.li`
  padding: 1rem;
  display: flex;
  color: inherit;
  background-color: ${({ theme }) => theme.colors.background.dark};
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows.subtle};
`

const ImageHolder = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 0.25rem;
  overflow: hidden;
  flex-shrink: 0;
`

const Content = styled.div`
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`

const Title = styled(Text)`
  margin-bottom: 0.5rem;
`

const PhoneWrapper = styled(Text)`
  margin-top: 0.5rem;
`

const ButtonWrapper = styled.div`
  margin-top: 0.5rem;
`
